/* src/app/support/supply-order/supply-order.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.supply-order,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.supply-order .ups-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.supply-order .ups-page-header .page-title {
  font-size: 1.1em;
}
@media screen and (min-width: 48rem) {
  .supply-order .ups-page-header .page-title {
    font-size: 1.25em;
  }
}
.supply-order .ups-page-header .page-actions {
  padding: 0.6rem 1em;
}
@media screen and (min-width: 48rem) {
  .supply-order .ups-page-header .page-actions {
    padding: 1rem 0;
  }
}
.supply-order .ups-page-header .page-actions .mat-mdc-raised-button {
  padding: 0 12px;
  height: 35px;
}
@media screen and (min-width: 48rem) {
  .supply-order .ups-page-header .page-actions .mat-mdc-raised-button {
    padding: 0px 32px;
    height: 45px;
  }
}
.supply-order .action {
  font-size: 0.8em;
}
.supply-order .form-body {
  padding: 1.5em 0;
}
.supply-order .supply-categories {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.supply-order .supply-categories .deprecated-key-values {
  width: 80%;
}
@media screen and (min-width: 48rem) {
  .supply-order .supply-categories .deprecated-key-values {
    margin-right: 3%;
    width: 47%;
  }
}
@media screen and (min-width: 64rem) {
  .supply-order .supply-categories .deprecated-key-values {
    margin-right: 3%;
    width: 30.3333333333%;
  }
}
.supply-order .supply-categories .deprecated-key-values .key-value {
  width: 100%;
}
.supply-order .supply-categories .deprecated-key-values .key-value .key {
  width: 30%;
}
.supply-order .supply-categories .deprecated-key-values .key-value .value {
  width: 70%;
  justify-content: flex-end;
}
.supply-order .supply-categories .deprecated-key-values .key-value.header .key {
  white-space: nowrap;
}
.supply-order .ship-to {
  width: 80%;
  padding: 1em;
  border: solid thin #d5cfc9;
  border-radius: 3px;
}
@media screen and (min-width: 48rem) {
  .supply-order .ship-to {
    width: 50%;
  }
}
@media screen and (min-width: 64rem) {
  .supply-order .ship-to {
    width: 30%;
  }
}
.supply-order .ship-to h5 {
  font-weight: 700;
}
.supply-order .ship-to .address-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.supply-order .last-supply-order {
  display: none;
  margin-right: 3em;
  margin-top: 1em;
}
@media screen and (min-width: 48rem) {
  .supply-order .last-supply-order {
    display: block;
  }
}
.supply-order .last-supply-order-mobile {
  display: block;
  padding: 0em 1.5em 1em 1.5em;
  margin-top: -1em;
}
@media screen and (min-width: 48rem) {
  .supply-order .last-supply-order-mobile {
    display: none;
  }
}
.supply-order .last-supply-order-mobile,
.supply-order .last-supply-order {
  font-size: 13px;
  font-weight: normal;
  color: #707070;
}
.supply-order .last-supply-order-mobile a.date,
.supply-order .last-supply-order-mobile a.detail,
.supply-order .last-supply-order a.date,
.supply-order .last-supply-order a.detail {
  font-weight: 600;
  font-size: 14px;
}
.supply-order .last-supply-order-mobile a.detail,
.supply-order .last-supply-order a.detail {
  text-decoration: underline;
}
.supply-order .last-supply-order-mobile .close-button .material-icons,
.supply-order .last-supply-order .close-button .material-icons {
  vertical-align: text-bottom;
  font-size: 18px;
  color: #8f8f8f;
}
/*# sourceMappingURL=supply-order.component.css.map */
