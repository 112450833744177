/* src/app/support/supply-order/order-detail/order-detail.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.order-details {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1.5em;
  font-size: 0.9em;
}
.order-details .order-detail {
  margin-bottom: 0.5em;
  width: 100%;
}
.order-details .order-items {
  width: 100%;
}
.order-details .order-date {
  display: flex;
  flex-direction: column;
  color: #242424;
}
.order-details .order-number {
  display: flex;
  flex-direction: column;
  color: #242424;
}
.order-details h4 {
  color: #242424;
}
.order-details .instruction {
  font-style: italic;
  font-weight: 500;
}
.order-details .total,
.order-details .instruction {
  color: #595555;
}
.order-details .total {
  margin: 1em 0 2.5em 1.5em;
  font-weight: 600;
}
.order-details .deprecated-key-values {
  width: 100%;
  color: #4a4a4a;
}
.order-details .deprecated-key-values .key-value {
  width: 100%;
}
.order-details .deprecated-key-values .key-value .key {
  width: 49%;
}
.order-details .deprecated-key-values .key-value .value {
  justify-content: flex-start;
  font-weight: normal;
  white-space: initial;
  width: 49%;
}
@media screen and (min-width: 48rem) {
  .order-details .deprecated-key-values .key-value .value {
    width: auto;
  }
}
.order-details .deprecated-key-values,
.order-details .key-value {
  padding: 0;
}
.order-details .table-wrapper {
  margin-left: 0;
}
@media screen and (min-width: 48rem) {
  .order-details .table-wrapper {
    margin-left: -3em;
  }
}
.order-details table.dataTable tbody th,
.order-details table.dataTable tbody td {
  padding: 8px 16px;
}
.order-details table.dataTable thead th {
  padding: 8px 16px;
}
/*# sourceMappingURL=order-detail.component.css.map */
